<template>
  <main class="w-full rounded-xl shadow-xl sm:w-10/12 md:w-8/12 mx-auto bg-white">
    <div class="w-full h-38 relative overflow-hidden">
      <img src="@/assets/img/new-referral-bg.svg" alt="" class="object-cover w-full h-72" />
      <div
        style="background-color: #11453b; opacity: 0.9"
        class="p-2 w-full text-center absolute bottom-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <h4 class="text-2xl md:text-3xl font-medium text-white md:text-mint" style="opacity: 1">Refer Us</h4>
      </div>
    </div>
    <div class="p-8">
      <h2 class="text-2xl mb-3 md:mb-4 text-primary :text-left md:text-3xl font-medium">Share And Earn Point</h2>
      <p class="text-sm md:text-lg text-justify capitalize text-primary">Invite your Friends</p>
      <p class="text-sm md:text-base mb-3 md:mb-4 text-justify capitalize">
        Copy your referral code or share your referral link with friends and family.
      </p>
      <p class="text-sm md:text-lg text-justify capitalize text-primary">They apply your referral code</p>
      <p class="text-sm md:text-base mb-3 md:mb-4 text-justify capitalize">
        Your friends and family apply your referral code when signing up with eazipay
      </p>
      <p class="text-sm md:text-lg text-justify capitalize text-primary">you make savings</p>
      <p class="text-sm md:text-base text-justify capitalize">
        you and your referral earn 1500 eazi coins for every single signup. 1000 coins is equivalent to 1000 naira
      </p>

      <div class="mt-6 font-medium text-xs md:text-lg">Share My Referral Link With Friends</div>
      <div class="mt-2 flex flex-col md:flex-row items-center gap-4">
        <span
          class="text-xs sm:text-sm truncate w-full text-grey border bg-newLimeGreen border-outlineGray rounded-lg md:rounded-xl px-4 py-3"
          >{{ referralCode }}</span
        >
        <span
          @click="copyFN"
          class="cursor-pointer hover:opacity-90 rounded-lg md:rounded-2xl bg-primary text-white px-7 text-lg py-3 font-medium"
          >Copy</span
        >
      </div>

      <div class="flex gap-8 items-center justify-center mt-8 mb-4">
        <a href="https://www.linkedin.com/company/myeazipay" target="”_blank”" attribute>
          <img class="cursor-pointer" src="@/assets/icons/new-linkedin.svg" alt="" />
        </a>
        <a href="https://www.instagram.com/myeazipay" target="”_blank”" attribute>
          <img class="" src="@/assets/icons/new-instagram.svg" alt="" />
        </a>
        <a href="https://twitter.com/myeazipay" target="”_blank”" attribute>
          <img class="" src="@/assets/icons/new-twitter.svg" alt="" />
        </a>
        <a href="https://wa.me/+2349134766185" target="”_blank”" attribute>
          <img class="" src="@/assets/icons/new-whatsapp.svg" alt="" />
        </a>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const referralCode = computed(() => {
  const code = store.getReferralCode.data && store.getReferralCode.data.referralCode;
  return code ? `${window.location.origin}/register?ref=${code}` : "";
});
console.log(referralCode.value);

const copyFN = async () => {
  await navigator.clipboard.writeText(referralCode.value);
  toast.success("Referral code copied to clipboard");
};
</script>

<style></style>
